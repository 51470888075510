'use strict';

/*
	DATA SIGNATURE FOR USAGE (with data-attribute) should be as follows.
	Don't forget the quotes around the object keys
	<my-element class='my-super-img' data-focal-point='{ "hasFocal": #myImgData.has_focal#, "height": #myImgData.height#, "width": #myImgData.width#, "x": #myImgData.focal_x_percent#, "y": #myImgData.focal_y_percent# }'></my-element>
*/

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var ScrollWatch = require('scrollwatch');

var domCache = require('dom-cache');
var imageProportion = require('image-proportion');
var throttle = require('lodash/function/throttle');

var instanceCount = 0;

var focalPoint = function(opts) {

	var dom;
	var options;
	var instanceId;
	var swInstance;

	var adjustImage = function($img, data) {

		var hasFocal = data.hasFocal;
		var isBackground = $img.is('img') ? false : true;
		var $container = isBackground ? $img : options.targetContainer ? $img.closest(options.targetContainer) : $img.parent();
		var containerWidth = $container.width() + parseFloat($container.css('padding-left')) + parseFloat($container.css('padding-right'));
		var containerHeight;
		var cover;
		var focal;
		var distanceFromCenter;
		var adjustedOffset;
		var imgCenter;
		var targetAxis;
		var targetDirection;
		var nonTargetDirection;
		var targetDimension;
		var targetOffset;
		var cssObj;

		if (data.isFixedParallax) {

			// Use the viewport as the containing height
			containerHeight = domCache.window.height();

		} else {

			containerHeight = $container.height() + parseFloat($container.css('padding-top')) + parseFloat($container.css('padding-bottom'));

		}

		cover = imageProportion.cover(data.width, data.height, containerWidth, containerHeight);

		// console.log(i, cover);

		// Determine which axis is overflowing and can be adjusted.
		if (cover.xOverflow) {

			targetAxis = 'x';
			targetDirection = 'left';
			nonTargetDirection = 'top';
			targetDimension = 'width';
			targetOffset = 'xOffset';

		} else {

			targetAxis = 'y';
			targetDirection = 'top';
			nonTargetDirection = 'left';
			targetDimension = 'height';
			targetOffset = 'yOffset';

		}

		// Center the image as a baseline, unless the image is a background
		// image b/c we are assuming it will be centered via css.
		if (!isBackground) {

			// Pull the image left or up to center.
			cssObj = {
				width: cover.width,
				height: cover.height
			};

			cssObj[targetDirection] = cover[targetOffset];

			// Reset the non target direction in case the container size has changed and the non target direction previously was the target direction
			cssObj[nonTargetDirection] = 0;

			$img.css(cssObj);

		}

		$img.attr('data-focal-point-processed', '');

		// Note: for the fixed parallax effect, the image must always have a focal point set via the data attribute, even if the user hasn't explicitly set a focal point, because we need to explicitly size and position the background image
		if (hasFocal) {

			// Determine the scaled location of the focal point.
			focal = data[targetAxis] / 100 * cover[targetDimension];

			// How far from the center is the focal point?
			imgCenter = cover[targetDimension] / 2;
			distanceFromCenter = imgCenter - focal;

			if (Math.abs(distanceFromCenter) > Math.abs(cover[targetOffset])) {

				// Honoring the focal point would adjust the image too much.
				// Determine which direction we are trying to go and make
				// sure the adjustment doesn't leave any whitespace.

				if (distanceFromCenter > 0) {

					// We are trying to push the image too far, don't let it go
					// past the edge.
					adjustedOffset = 0;

				} else {

					// We are trying to pull the image too far, don't let it go
					// past the edge. Adding 1 to deal with browser rounding.
					// It's better to have the focal point be off by 1px than
					// pull the image too far and have a 1px gap in the container.
					adjustedOffset = Math.ceil(cover[targetOffset] * 2) + 1;

				}

			} else {

				// We can honor the focal point.
				adjustedOffset = cover[targetOffset] + distanceFromCenter;

			}

			if (isBackground) {

				if (cover.xOverflow) {

					if (data.isFixedParallax) {

						$img.css({
							// Set an explicit cover size for our parallax container. We can't use a css cover because the browser will think we are trying to cover the entire viewport, due to the fixed background attachment.
							'background-size': cover.width + 'px ' + cover.height + 'px',
							// Factor in the positioning in the viewport of the parallax container
							'background-position': ($img.offset().left + adjustedOffset) + 'px 0px'
						});

					} else {

						$img.css('background-position', adjustedOffset + 'px center');

					}

				} else {

					if (data.isFixedParallax) {

						$img.css({
							// Set an explicit cover size for our parallax container. We can't use a css cover because the browser will think we are trying to cover the entire viewport, due to the fixed background attachment.
							'background-size': cover.width + 'px ' + cover.height + 'px',
							// Factor in the positioning in the viewport of the parallax container
							'background-position': $img.offset().left + 'px ' + adjustedOffset + 'px'
						});

					} else {

						$img.css('background-position', 'center ' + adjustedOffset + 'px');

					}

				}

			} else {

				$img.css(targetDirection, adjustedOffset);

			}

		}

	};

	var adjust = function() {

		if (options.watchMode) {

			// Adjust any image that has previously been adjusted
			dom.imgs.filter('[data-focal-point-processed]').each(function() {

				var $img = $(this);

				adjustImage($img, $img.data('focal-point'));

			});

		} else {

			// Adjust all images
			dom.imgs.each(function(i) {

				var $img = dom.imgs.eq(i);
				var data = options.data && options.data[i] || $img.data('focal-point');

				adjustImage($img, data);

			});

		}

	};

	var setupDom = function() {

		dom = {};

		dom.imgs = $(options.target);

	};

	var setupOptions = function(o) {

		options = {
			target: '[data-focal-point]',
			targetContainer: '',
			watchMode: false,
			watchOffset: 250,
			scrollThrottle: 100,
			resizeThrottle: 100,
			// Allow data to be passed in or read from the DOM
			data: null
		};

		$.extend(options, o);

	};

	var addEventHandlers = function() {

		domCache.window.off('.focal-point-' + instanceId);
		domCache.window.on('resize.focal-point-' + instanceId, throttle(adjust, 100));

	};

	var init = function() {

		instanceId = instanceCount++;
		setupOptions(opts);
		setupDom();
		addEventHandlers();

		// Note: watchMode can only be used when data is in data-focal-point attribute
		if (options.watchMode) {

			// Setup a watch to apply focal point when images are close to being in the viewport

			swInstance = new ScrollWatch({
				watch: options.target,
				// Use focal point specific classes to avoid collisions with any other watchers from other modules, lazy-image for example
				ignoreClass: 'focal-point-scroll-watch-ignore',
				inViewClass: 'focal-point-scroll-watch-in-view',
				watchOffset: options.watchOffset,
				scrollThrottle: options.scrollThrottle,
				resizeThrottle: options.resizeThrottle,
				onElementInView: function(obj) {

					var $img = $(obj.el);

					adjustImage($img, $img.data('focal-point'));

				}
			});


		} else {

			// Apply focal point to all images
			adjust();

		}

	};

	var refresh = function() {

		swInstance.refresh();

	};

	var destroy = function() {

		if (swInstance) {

			swInstance.destroy();
			swInstance = null;

		}

		domCache.window.off('.focal-point-' + instanceId);
		dom = null;
		options = null;
		instanceId = null;

	};

	init();

	return {
		destroy: destroy,
		adjust: adjust,
		refresh: refresh
	};

};

module.exports = focalPoint;
